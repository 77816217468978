/*  
  Developed by Nicola Vivarelli and Geffrey Paul Tags IV 'O

  Remember to change loading time of logo in public/index.html before 
  making changes, if not preparati a bestemmiare perchè devi aspettare 
  2.5 secondi per vedere l'outcome di ogni modifica. 

  Just kidding, but seriously, change the loading time of the logo
  in public/index.html to 0ms before making changes.

  Portfolio of Nicola Vivarelli showing how cool he is 👌🏼😎
  Made with React and Tailwind CSS.

  🚨🚨🚨 CURRENT PROBLEMS: 🚨🚨🚨 
  1)  Phone Layout not working properly, need to fix it.
      There are several ways to fix it, but I'm too lazy to do it now.
      I'll fix it later, I promise.

  2)  Footer not working properly, need to fix it.

  3)  Scroll Arrow needs to face downwards only when in the first page, 
      need to fix it.
      Remember to adjust the arrow position when the location
      is in the last page, as it nees to be pointing upwards.
      SOMEONE FIX THIS!!!

  4)  Navbar not working properly, need to fix it.

  5)  Black bands on mobile, and the app doesn't fill the screen, 
      need to fix it ASAP. 🚨


    ✅✅✅ POSSIBLE SOLUTIONS: ✅✅✅
    1) A possible solution to solve the arrow problem when
      the user scrolls to the bottom of the page is to use
      a technique that allows to detect the scroll position
      OR to divide the web pages into actual files with each
      secion in order to manage the arrow in each section of
      the pwa.
      
    2) By dividing the sections into files, there could be
      the possibility to solve the phone layout problem as well as
      the footer.
*/

import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Link } from 'react-scroll';
import logo from './logo.svg';
import './App.css';
import { FaLinkedin } from 'react-icons/fa';
import { FiMenu, FiX } from 'react-icons/fi';

function App() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="App bg-gray-100 dark:bg-gray-900 min-h-screen text-gray-800 dark:text-gray-100 overflow-x-hidden overscroll-none">
      <header>
        {/* Navbar */}
        <nav className="fixed w-full z-50 bg-opacity-70 text-white p-4 shadow-lg bg-gray-800 overflow-x-hidden">
          <div className="container mx-auto flex justify-between items-center">
            <motion.h1 
              className="text-xl font-bold cursor-pointer" 
              initial={{ y: -20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.6 }}
            >
              Nicola Vivarelli
            </motion.h1>
            <div className="md:hidden">
              <button onClick={toggleMenu} className="text-white focus:outline-none">
                {isMenuOpen ? (
                  <FiX className="w-6 h-6" />
                ) : (
                  <FiMenu className="w-6 h-6" />
                )}
              </button>
            </div>
            <motion.ul 
              className={`md:flex space-x-8 items-center ${isMenuOpen ? 'block' : 'hidden'} md:block`}
              initial={{ y: -20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.8 }}
            >
              <AnimatePresence>
                {isMenuOpen && (
                  <motion.div
                    initial={{ height: 0, opacity: 0 }}
                    animate={{ height: "auto", opacity: 1 }}
                    exit={{ height: 0, opacity: 0 }}
                    transition={{ duration: 0.5 }}
                    className="md:hidden flex flex-col space-y-4 mt-4 overflow-x-hidden"
                  >
                    {['about', 'project', 'works', 'contact'].map((section) => (
                      <li className="group relative" key={section}>
                        <Link 
                          to={section} 
                          smooth={true} 
                          duration={1000} 
                          className="hover:text-blue-400 cursor-pointer transition-all block py-2 md:inline md:py-0"
                          onClick={() => setIsMenuOpen(false)} // Chiudi il menu dopo aver cliccato
                        >
                          {section.charAt(0).toUpperCase() + section.slice(1)}
                        </Link>
                        <div className="absolute w-0 h-1 bg-blue-400 bottom-0 left-0 transition-all duration-300 group-hover:w-full"></div>
                      </li>
                    ))}
                  </motion.div>
                )}
              </AnimatePresence>
              {['about', 'project', 'works', 'contact'].map((section) => (
                <li className="group relative md:block hidden" key={section}>
                  <Link 
                    to={section} 
                    smooth={true} 
                    duration={1000} 
                    className="hover:text-blue-400 cursor-pointer transition-all block py-2 md:inline md:py-0"
                  >
                    {section.charAt(0).toUpperCase() + section.slice(1)}
                  </Link>
                  <div className="absolute w-0 h-1 bg-blue-400 bottom-0 left-0 transition-all duration-300 group-hover:w-full"></div>
                </li>
              ))}
            </motion.ul>
          </div>
        </nav>
      </header>

      <main id="scroll-container" className="snap-y snap-mandatory h-screen w-full overflow-y-auto overflow-x-hidden overscroll-none">
        {/* Hero Section */}
        <section id="hero" className="snap-start min-h-screen flex flex-col justify-center items-center text-center py-20 w-full overflow-x-hidden">
          <motion.img 
            src={logo} 
            className="App-logo mx-auto mb-4 w-32 h-32" 
            alt="logo"
            initial={{ scale: 0.5, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 1 }}
          />
          <motion.h1
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
            className="text-4xl font-bold"
          >
            Hello, I'm Nicola Vivarelli
          </motion.h1>
          <motion.p
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1, delay: 0.2 }}
            className="text-lg mt-2"
          >
            Computer Scientist, Engineering Student
          </motion.p>
        </section>  

        {/* About Section */}
        <section id="about" className="snap-start min-h-screen flex flex-col justify-center items-center py-20 bg-white dark:bg-gray-800 text-gray-800 dark:text-gray-100 w-full overflow-x-hidden">
          <motion.div 
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 1 }}
            className="container mx-auto text-center flex flex-col items-center"
          >
            <h2 className="text-3xl font-bold mb-4">About Me</h2>
            <p className="text-lg max-w-2xl mb-4">
              I am Nicola Vivarelli, a dedicated Computer Scientist and Engineer with a strong foundation in web development and artificial intelligence. I graduated in Computer Science from the <a href="https://www.unife.it/" className="link-style" target="_blank" rel="noopener noreferrer">University of Ferrara</a> and am currently pursuing a Master's degree in Computer Engineering at <a href="https://www.polito.it/en/" className="link-style" target="_blank" rel="noopener noreferrer">Politecnico di Torino</a>.
            </p>
            <a 
              href="https://www.linkedin.com/in/nicolavivarelli/" 
              target="_blank" 
              rel="noopener noreferrer" 
              className="text-blue-500 dark:text-blue-400 hover:underline flex items-center space-x-2 transform transition-transform duration-300 hover:scale-110"
            >
              <FaLinkedin className="w-6 h-6" />
              <span>LinkedIn</span>
            </a>
          </motion.div>
        </section>

        {/* Project Section */}
        <section id="project" className="snap-start min-h-screen flex flex-col justify-center items-center py-20 bg-gray-100 dark:bg-gray-900 text-gray-800 dark:text-gray-100 w-full overflow-x-hidden">
          <motion.div 
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 1 }}
            className="container mx-auto text-center flex flex-col items-center"
          >
            <h2 className="text-3xl font-bold mb-4">Projects Samples</h2>
          </motion.div>
        </section>

        {/* Works Section */}
        <section id="works" className="snap-start min-h-screen flex flex-col justify-center items-center py-20 bg-white dark:bg-gray-800 text-gray-800 dark:text-gray-100 w-full overflow-x-hidden">
          <motion.div 
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 1 }}
            className="container mx-auto text-center flex flex-col items-center"
          >
            <h2 className="text-3xl font-bold mb-4">My Latest Works</h2>
          </motion.div>
        </section>

        {/* Contact Section */}
        <section id="contact" className="snap-start min-h-screen flex flex-col justify-center items-center py-20 bg-gray-100 dark:bg-gray-900 text-gray-800 dark:text-gray-100 w-full overflow-x-hidden">
          <motion.div 
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 1 }}
            className="container mx-auto text-center flex flex-col items-center"
          >
            <h2 className="text-3xl font-bold mb-4">Contact Me</h2>
            <p className="text-lg mb-4">Feel free to reach out for inquiries or if you have any questions!</p>
            <a 
              href="mailto:nicolavivarelli@outlook.com" 
              className="text-blue-500 dark:text-blue-400 hover:underline"
            >
              nicolavivarelli@outlook.com
            </a>
          </motion.div>
        </section>
      </main>

      {/* Footer Scroll Arrow */}
      <footer className="fixed bottom-4 w-full flex justify-center">
        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, delay: 1 }}
          className="scroll-arrow"
        >
          <Link to="about" smooth={true} duration={1000} className="cursor-pointer">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10 text-blue-500 animate-bounce" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
            </svg>
          </Link>
        </motion.div>
      </footer>
    </div>
  );
}

export default App;
